import { AppBar, Grid, Toolbar, Typography } from "@mui/material";

function AppNavBar({
  userName,
  logo,
  logoUrl,
  Last,
}: {
  userName?: string;
  logo?: string;
  logoUrl?: string;
  Last?: () => JSX.Element;
}) {
  return (
    <>
      <AppBar
        style={{
          background: "white",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          position: "fixed",
          left: 0,
          right: 0,
          top: 0,
          zIndex: 1,
          transition: "-moz-initial.3s",
          padding: "0 8px",
          height: 72,
        }}
        className="animate__animated animate__fadeIn"
      >
        <Toolbar
          style={{
            alignItems: "center",
            height: "100%",
            color: "white",
          }}
          className="container"
        >
          <Grid
            alignItems={"center"}
            justifyContent={"space-between"}
            container
          >
            <Grid
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {logo ? (
                <img
                  src={logo}
                  alt=""
                  style={{
                    // maxHeight: 32,
                    marginRight: 8,
                  }}
                  onClick={() =>
                    logoUrl ? window.open(logoUrl, "_self") : null
                  }
                />
              ) : null}
              {userName ? (
                <div
                  onClick={() =>
                    logoUrl ? window.open(logoUrl, "_self") : null
                  }
                >
                  <Typography
                    variant="subtitle1"
                    fontSize={18}
                    fontWeight={500}
                    style={{
                      color: "black",
                    }}
                  >
                    {userName}
                  </Typography>
                </div>
              ) : null}
            </Grid>
            <Grid>
              {Last ? (
                <div>
                  <Last />
                </div>
              ) : null}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default AppNavBar;
