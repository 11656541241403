import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import App from "./App";
import Login from "./views/payinpayout/login";
import Payinpayout from "./views/payinpayout/payinpayout";
import Calculate from "./views/payout/Calculate";


function Main() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/calculate" element={<Calculate />} />
        <Route path="/payinpayout" element={<Payinpayout />} />
        {/* <Route path="/login" element={<Login />} /> */}

      </Routes>
    </Router>
  );
}

export default Main;
