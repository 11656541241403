import { Grid } from "@mui/material";
import React from "react";

function AppInputLogin(
  props: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
) {
  return (
    <Grid
      style={{
        position: "relative",
      }}
    >
      <input
        style={{
          padding: "10px 16px",
          border: '1px solid #DFE5EB',
          borderRadius: 8,
          fontSize: "15px",
          transition: ".3s",
          width: "100%",
          paddingLeft: 70,
        }}
        {...props}
      />
      <Grid
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid #DFE5EB",
          background: "#ffff",
          borderRadius: "8px 0 0 8px",
          width: 54,
          fontSize: 15,
          color: "#516375",
        }}
      >
        +91
      </Grid>
    </Grid>
  );
}

export default AppInputLogin;
