import { Grid, Typography } from "@mui/material";
import React from "react";

function AppDotsLoading({ text }: { text?: string }) {
  return (
    <>
      <Grid className="dots-loading-container">
        <div className="dots-loading">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        {text ? (
          <Grid>
            <Typography>{text}</Typography>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}

export default AppDotsLoading;
