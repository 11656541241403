import axios, { AxiosRequestConfig } from "axios";

declare const window: Window &
  typeof globalThis & {
    moe: any;
  };
export default class Utils {
  static async Get(
    baseUrl: string,
    EndPoint: string,
    sessionId: string
  ): Promise<any> {
    let token = sessionId;
    let config: AxiosRequestConfig = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    try {
      const response = await axios.get(baseUrl + EndPoint, config);
      return response.data;
    } catch (error: any) {
      this.handleErrors(error);
      return error;
    }
  }
  static async Post(
    baseUrl: string,
    EndPoint: string,
    body: any,
    sessionId: string
  ): Promise<any> {
    let data = JSON.stringify(body);
    let token = sessionId;
    try {
      const response = await axios({
        url: baseUrl + EndPoint,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        method: "POST",
        data: data,
      });
      return response.data;
    } catch (error: any) {
      this.handleErrors(error);
      return error;
    }
  }

  static handleErrors = (error: any) => {
    if (error.response) {
      if (
        error.response.data &&
        error.response.data.msg &&
        typeof error.response.data.msg === "string"
      ) {
        console.log(error.response.data.msg || error.response.statusText);
      }
    }
  };
  static trackEvent(event: string, data?: any) {
    const Moengage = window.moe({
      app_id: "TCD8BJHMJ0UDZVZH58YV49CT",
      debug_logs: 0,
    });
    try {
      if (data && Object.keys(data).length) {
        Moengage.track_event(event, data);
      } else {
        Moengage.track_event(event);
      }
    } catch (error) {}
  }


  

  static EndPoints = {
    DROP_DOWN_MASTER :'/payoutgrid/v1/getDropdownMasters',
    SEARCH_DROPDOWN : "/payoutgrid/v1/searchDropdown",
    FETCH_PAYOUTDATA : "/payoutgrid/v1/fetchPayoutData"
  }
}
