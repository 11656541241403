import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { useNavigate } from "react-router-dom";
import { vehicleAge } from "../../helper/constants";
import { insurerListInterface } from "../../helper/Interface/interface";
import logoutImg from "../../assets/images/logout.png";
import { Box } from "@mui/system";
import Utils from "../../Utils/utils";
import {
  BaseUrl_Stg,
  Goods_Carrying_Vehicle,
  Miscellaneous_Vehicle,
  Passenger_Carrying_Vehicle,
  Private_Car,
  Two_Wheeler,
} from "../../Utils/constants";

function Payinpayout() {
  const navigate = useNavigate();
  const ref = useRef();
  const [vehicleType, setVehicleType] = useState<any>(null);
  const [rtoCity, setRtoCity] = useState<any>(null);
  const [policyType, setPolicyType] = useState<any>(null);
  const [planType, setPlanType] = useState<string>("");
  const [carname, setCarname] = useState<any>(null);
  const [selectedInsurerNames, setSelectedInsurerNames] = useState<any[]>([]);
  const [subVehicle, setSubVehicle] = useState<any>();
  const [date, setDate] = useState<string>("");
  const [page, setpageno] = useState(0);
  const [selected, setSelected] = useState([]);
  const [togel, settogel] = useState(0);
  const [togelage, settogelage] = useState(0);
  const [day, setday] = useState<string>("");
  const [month, setmonth] = useState<string>("");
  const [year, setyear] = useState<string>("");
  const [data, setdata] = useState([
    "Grid Month",
    "Vehicle Age",
    "Insurer",
    "NCB",
  ]);

  const [vehicleTypeList, setVehicleTypeList] = useState<any>([]);
  const [insurerList, setInsurerList] = useState<insurerListInterface[]>([]);
  const [vehicleSubTypeList, setVehicleSubTypeList] = useState<any>([]);
  const [policyTypeList, setPolicyTypeList] = useState<any>([]);
  const [plan, setPlanTypeList] = useState<any>([]);
  const [SearchName, setSearchName] = useState<any>(null);
  const [SearchRto, setSearchRto] = useState<any>(null);
  const [SearchVehilcle, setSearchVehilcle] = useState<string>("");
  const [payoutData, setPayoutData] = useState<any>([]);
  const [code, setCode] = useState<string>("");
  const [pycode, setPyCode] = useState<any>(null);
  const [checked, setChecked] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [close, setClose] = useState<boolean>(false);

  useEffect(() => {
    if (localStorage.getItem("G_T")) {
      getDropdownMasters();
    } else {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    setCarname(null);
  }, [vehicleType]);

  const ButtonDisable = () => {
    let status = true;
    if (
      vehicleType != null &&
      rtoCity?.lenght != 0 &&
      policyType?.lenght != 0 &&
      planType.length != 0 &&
      selectedInsurerNames.length != 0 &&
      carname != null
    ) {
      status = false;
    }
    if (
      vehicleType == Miscellaneous_Vehicle &&
      rtoCity?.lenght != 0 &&
      policyType?.lenght != 0 &&
      planType.length != 0 &&
      selectedInsurerNames.length != 0
    ) {
      status = false;
    }
    return status;
  };

  const isAllSelected =
    insurerList.length > 0 &&
    selectedInsurerNames.length === insurerList.length;

  const allSeleted = (event: any) => {
    setChecked(event.target.checked);
    if (!checked) {
      let selected = [];
      for (let index = 0; index < insurerList.length; index++) {
        selected.push(insurerList[index].Code);
      }
      setSelectedInsurerNames(selected);
    } else {
      setSelectedInsurerNames([]);
    }
  };

  const selectInsurer = (item: string) => {
    setChecked(false);
    let selectedIns = [];
    selectedIns.push(item);
    setSelectedInsurerNames(selectedIns);
  };

  const selecter = (item: any) => {
    setCarname(item);
    setSearchName(null);
  };

  const rtoSelecter = (item: any) => {
    setRtoCity(item);
    setSearchRto(null);
  };

  const planTypeselecter = (item: any) => {
    setPolicyType(item);
    setPyCode(item.Code);
  };

  const vehicleSelecter = (item: any) => {
    setVehicleType(item);
    setCode(item.Code);
  };

  const logout = () => {
    if (localStorage.getItem("G_T")) {
      localStorage.clear();
      navigate("/");
    }
  };

  const doMMVFilter = (query: string) => {
    setTimeout(() => {
      let text = query;
      let result = text.toUpperCase();
      getMmvSearchDropdown(result);
    }, 2000);
  };

  const getDropdownMasters = async () => {
    const response = await Utils.Get(
      BaseUrl_Stg,
      Utils.EndPoints.DROP_DOWN_MASTER,
      `Bearer ${localStorage.getItem("jwtToken")}`
    );
    if (response && response.isSuccess) {
      setVehicleTypeList(response.data.vehicleTypeList);
      setInsurerList(response.data.insurerList);
      setVehicleSubTypeList(response.data.vehicleSubTypeList);
      setPolicyTypeList(response.data.policyTypeList);
      setPlanTypeList(response.data.planTypeList);
    }
  };

  const resetInsurer = () => {
    setSelectedInsurerNames([]);
    setChecked(false);
  };
  const handleChange = (event: any) => {
    const value = event.target.value;
    // if (value[value.length - 1] === "all") {
    //   setSelected(selected.length === insurerList.length ? [] : insurerList);
    //   return;
    // }
    setSelectedInsurerNames(value);
    setChecked(false);
  };
  const getMmvSearchDropdown = async (text: string) => {
    const getSearchResult = await Utils.Post(
      BaseUrl_Stg,
      Utils.EndPoints.SEARCH_DROPDOWN,
      {
        type: "mmv",
        searchText: text,
        vehicleTypeCode: vehicleType,
      },
      `Bearer ${localStorage.getItem("jwtToken")}`
    );
    if (getSearchResult.isSuccess) {
      setSearchName(getSearchResult.data.searchResult);
    } else {
      setSearchName(null);
    }
  };

  const getRtoSearchDropdown = async (text: string) => {
    const getSearchResult = await Utils.Post(
      BaseUrl_Stg,
      Utils.EndPoints.SEARCH_DROPDOWN,
      {
        type: "rto",
        searchText: text,
      },
      `Bearer ${localStorage.getItem("jwtToken")}`
    );
    if (getSearchResult && getSearchResult.isSuccess) {
      setSearchRto(getSearchResult.data.searchResult);
    } else {
      setSearchName(null);
    }
  };

  const fetchPayoutData = async () => {
    const getPayuot = await await Utils.Post(
      BaseUrl_Stg,
      Utils.EndPoints.FETCH_PAYOUTDATA,
      {
        vehicleType: vehicleType,
        rto: rtoCity,
        policyType: policyType,
        planType: planType,
        insurerName: selectedInsurerNames,
        mmvDetails: carname,
      },
      `Bearer ${localStorage.getItem("jwtToken")}`
    );
    if (getPayuot.isSuccess) {
      setPayoutData(getPayuot.data.searchResult);
      setLoader(false);
      setpageno(page + 1);
    } else {
      setError(true);
    }
  };

  const movePayuot = () => {
    fetchPayoutData();
    // setpageno(page + 1);
  };

  const VehicleAge = () => {
    return (
      <Grid sx={{}}>
        <Grid
          sx={{
            padding: "16px 24px",
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            background: "#FFFFFF",
          }}
        >
          <Grid sx={{ width: "100%", display: "flex", paddingRight: "12px" }}>
            <Grid sx={{ flex: "10" }}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "24px",
                  textAlign: "left",
                  color: "#232B33",
                }}
              >
                Vehicle Age
              </Typography>
            </Grid>
            <Grid onClick={() => settogelage(0)}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "900",
                  lineHeight: "24px",
                  textAlign: "right",
                  color: "#63798F",
                  marginTop: "-10px",
                }}
              >
                X
              </Typography>
            </Grid>
          </Grid>
          <Grid
            sx={{
              paddingTop: "12px",
              paddingBottom: "32px",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {vehicleAge.map((item: any) => {
              return (
                <li
                  style={{
                    border: `1px solid #C3CED9`,
                    padding: "12px",
                    width: "22%",
                    margin: "8px 0 0 8px",
                  }}
                >
                  {item.label}
                </li>
              );
            })}
          </Grid>
          <Grid
            sx={{
              paddingTop: "16px",
              borderTop: "1px solid #DFE5EB",
              width: "100%",
            }}
          >
            <Button variant="contained" type="submit" fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {page == 0 ? (
        <Grid
          sx={{
            width: "100%",
            maxWidth: "720px",
            margin: "0 auto",
          }}
        >
          <Grid sx={{ width: "100%", display: "flex", background: "#F0F6FD" }}>
            {page < 0 ? (
              <Grid sx={{ flex: "1", padding: "14px 0 0 20px" }}>
                <img
                  src="../images/backIcon.png"
                  style={{ width: "12px", height: "10px", cursor: "pointer" }}
                  onClick={() => setpageno(page - 1)}
                />
              </Grid>
            ) : null}
            <Grid
              sx={{
                padding: "16px 0px",
                flex: "10",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "525",
                  lineHeight: "24px",
                  textAlign: "left",
                  color: "#232B33",
                  paddingLeft: "16px",
                }}
              >
                Vehicle Coins
              </Typography>
            </Grid>
            <Grid sx={{ flex: "2", paddingTop: "12px", alignItems: "left" }}>
              <img
                src={logoutImg}
                style={{ width: "24px", height: "24px", cursor: "pointer" }}
                onClick={() => logout()}
              />
              {/* <Button onClick={() => logout()}>Logout</Button> */}
            </Grid>
          </Grid>
          {/* HEADER */}

          <Grid
            sx={{
              width: "100%",
              padding: "24px",
            }}
          >
            {/* CHECK  */}
            {/* <Grid
              sx={{
                width: "100%",
                background: "#F0F6FD",
                borderRadius: "4px",
              }}
            >
              <Grid sx={{ width: "100%", display: "flex", padding: "12px" }}>
                <Grid sx={{ flex: "10" }}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: "12px",
                      fontWeight: "425",
                      lineHeight: "16px",
                      textAlign: "left",
                      color: "#006880",
                    }}
                  >
                    Check how much you have earned after selling a policy
                  </Typography>
                </Grid>
              </Grid>
            </Grid> */}

            <Grid sx={{ width: "100%" }}>
              <Grid display={"flex"}>
                <Grid flex={8}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: "12px",
                      fontWeight: "525",
                      lineHeight: "16px",
                      textAlign: "left",
                      color: "#404E5C",
                      paddingBottom: "4px",
                    }}
                  >
                    Vehicle Type*
                  </Typography>
                </Grid>
                <Grid sx={{ alignItems: "right", marginTop: "-12px" }}>
                  <Button onClick={() => window.location.reload()}>
                    RESET
                  </Button>
                </Grid>
              </Grid>
              {/* {vehicleType == null ? ( */}
              <FormControl sx={{ m: 1, minWidth: "100%", maxWidth: "400px" }}>
                <Select
                  value={vehicleType}
                  onChange={(event: any) => {
                    setVehicleType(event.target.value);
                  }}
                  sx={{ height: 40, marginLeft: "-10px !important" }}
                  required
                >
                  {vehicleTypeList
                    .filter((el: any) => el.Code !== Two_Wheeler)
                    .map((Item: any, index: any) => (
                      <MenuItem
                        value={Item.Code}
                        disabled={Item.Code == Two_Wheeler ? true : false}
                      >
                        {Item.Name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              {/* ) : (
                <Grid sx={{ display: "flex", padding: "8px 0px" }}>
                  <Grid flex={10}>
                    <Grid display={"flex"}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          lineHeight: "24px",
                          textAlign: "left",
                          color: "#232B33",
                          marginLeft: "8px",
                        }}
                      >
                        {vehicleType.Name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    onClick={() => setVehicleType(null)}
                    flex={2}
                    sx={{ cursor: "pointer" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "900",
                        lineHeight: "24px",
                        textAlign: "right",
                        color: "#63798F",
                        marginTop: "-10px",
                      }}
                    >
                      X
                    </Typography>
                  </Grid>
                </Grid>
              )} */}
            </Grid>
            {/* {rtoCity == null ? ( */}
            <Grid
              sx={{ width: "100%", paddingTop: "20px", position: "relative" }}
            >
              <Typography
                sx={{
                  width: "100%",
                  fontSize: "12px",
                  fontWeight: "525",
                  lineHeight: "16px",
                  textAlign: "left",
                  color: "#404E5C",
                  paddingBottom: "4px",
                }}
              >
                Enter RTO*
              </Typography>
              {/* <AppInput
                  onChange={(event: any) =>
                    getRtoSearchDropdown(event.target.value)
                  }
                /> */}
              <DebounceInput
                minLength={2}
                debounceTimeout={500}
                onChange={(e: any) => getRtoSearchDropdown(e.target.value)}
                style={{
                  minWidth: "100%",
                  maxWidth: "400px",
                  height: "40px",
                  padding: "8px",
                }}
                value={rtoCity}
              />
              {SearchRto == null ? null : (
                <Grid
                  sx={{
                    position: "absolute",
                    top: "40",
                    right: "0",
                    zIndex: "5",
                  }}
                >
                  <Button onClick={() => setSearchRto(null)}>x</Button>
                </Grid>
              )}
              {SearchRto == null ? null : (
                <Grid
                  sx={{
                    position: "absolute",
                    top: "80",
                    left: "0",
                    maxHeight: "200px",
                    overflow: "scroll",
                    width: "100%",
                    background: "#ffff",
                    zIndex: "3",
                    borderRadius: "4px",
                    boxShadow:
                      "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
                  }}
                >
                  {SearchRto.map((Item: any, index: any) => (
                    <ul>
                      <li
                        style={{ cursor: "pointer", width: "100%" }}
                        onClick={() => rtoSelecter(Item.name)}
                      >
                        {Item.name}
                      </li>
                    </ul>
                  ))}
                </Grid>
              )}
            </Grid>
            {/* ) : (
              <Grid sx={{ display: "flex", padding: "8px 0px" }}>
                <Grid flex={10}>
                  <Grid display={"flex"}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "24px",
                        textAlign: "left",
                        color: "#232B33",
                      }}
                    >
                      Selected RTO :
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        lineHeight: "24px",
                        textAlign: "left",
                        color: "#232B33",
                        marginLeft: "8px",
                      }}
                    >
                      {rtoCity}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  onClick={() => setRtoCity(null)}
                  flex={2}
                  sx={{ cursor: "pointer" }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "900",
                      lineHeight: "24px",
                      textAlign: "right",
                      color: "#63798F",
                      marginTop: "-10px",
                    }}
                  >
                    X
                  </Typography>
                </Grid>
              </Grid>
            )} */}

            <Grid sx={{ paddingTop: "20px" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: "12px",
                  fontWeight: "525",
                  lineHeight: "16px",
                  textAlign: "left",
                  color: "#404E5C",
                  paddingBottom: "4px",
                }}
              >
                Policy Type*
              </Typography>
              {/* {policyType == null ? ( */}
              <FormControl sx={{ m: 1, minWidth: "100%", maxWidth: "400px" }}>
                <Select
                  onChange={(event: any) => setPolicyType(event.target.value)}
                  value={policyType}
                  sx={{ height: 40, marginLeft: "-10px !important" }}
                >
                  <MenuItem value="COMP">Comprehensive</MenuItem>
                  {vehicleType == Two_Wheeler || vehicleType == Private_Car ? (
                    <MenuItem value="SAOD">SAOD</MenuItem>
                  ) : null}
                  <MenuItem value="SATP">SATP</MenuItem>
                </Select>
              </FormControl>
              {/* ) : (
                <Grid sx={{ display: "flex", padding: "8px 0px" }}>
                  <Grid flex={10}>
                    <Grid display={"flex"}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          lineHeight: "24px",
                          textAlign: "left",
                          color: "#232B33",
                          marginLeft: "8px",
                        }}
                      >
                        {policyType}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    onClick={() => setPolicyType(null)}
                    flex={2}
                    sx={{ cursor: "pointer" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "900",
                        lineHeight: "24px",
                        textAlign: "right",
                        color: "#63798F",
                        marginTop: "-10px",
                      }}
                    >
                      X
                    </Typography>
                  </Grid>
                </Grid>
              )} */}
            </Grid>
            <Grid sx={{ width: "100%", paddingTop: "20px" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: "12px",
                  fontWeight: "525",
                  lineHeight: "16px",
                  textAlign: "left",
                  color: "#404E5C",
                  paddingBottom: "4px",
                }}
              >
                Plan Type*
              </Typography>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="rollover"
                sx={{ display: "contents" }}
                onChange={(item: any) => setPlanType(item.target.value)}
                value={planType}
              >
                <FormControlLabel
                  value={"RV"}
                  control={<Radio />}
                  label={"Rollover"}
                />

                <FormControlLabel
                  value={"REN"}
                  control={<Radio />}
                  label={"Renewal"}
                />
                {pycode == "COMP" ? (
                  <FormControlLabel
                    value={"NEW"}
                    control={<Radio />}
                    label={"New"}
                  />
                ) : null}
              </RadioGroup>
            </Grid>

            {vehicleType == Private_Car ||
            vehicleType == Two_Wheeler ||
            vehicleType == Goods_Carrying_Vehicle ||
            vehicleType == Passenger_Carrying_Vehicle ? (
              <>
                {carname == null ? (
                  <Grid
                    sx={{
                      width: "100%",
                      paddingTop: "20px",
                      position: "relative",
                    }}
                  >
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: "12px",
                        fontWeight: "525",
                        lineHeight: "16px",
                        textAlign: "left",
                        color: "#404E5C",
                        paddingBottom: "4px",
                      }}
                    >
                      Vehicle Name*
                    </Typography>
                    {/* <AppInput
                      onChange={(item: any) => doMMVFilter(item.target.value)}
                    /> */}
                    <DebounceInput
                      minLength={2}
                      debounceTimeout={500}
                      onChange={(e) => getMmvSearchDropdown(e.target.value)}
                      style={{
                        minWidth: "100%",
                        maxWidth: "400px",
                        height: "40px",
                        padding: "8px",
                      }}
                    />

                    {SearchName == null ? null : (
                      <Grid
                        sx={{
                          position: "absolute",
                          top: "40",
                          right: "0",
                          zIndex: "5",
                        }}
                      >
                        <Button onClick={() => setSearchName(null)}>x</Button>
                      </Grid>
                    )}
                    {SearchName == null ? null : (
                      <>
                        {/* <Grid display={"flex"}>
                          <Grid flex={8}></Grid>
                          <Grid
                            sx={{
                              zIndex: "5",
                              position: "fixed",
                              right: "8px",
                            }}
                          >
                            <Button onClick={() => setSearchName(null)}>
                              x
                            </Button>
                          </Grid>
                        </Grid> */}
                        <Grid
                          sx={{
                            position: "absolute",
                            top: "80",
                            left: "0",
                            maxHeight: "200px",
                            overflow: "scroll",
                            zIndex: "3",
                            background: "#ffff",
                            borderRadius: "4px",
                            boxShadow:
                              "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
                            width: "100%",
                          }}
                        >
                          {SearchName.map((Item: any, index: any) => (
                            <ul style={{ marginTop: "12px" }}>
                              <li
                                style={{ cursor: "pointer", width: "90%" }}
                                onClick={() => selecter(Item)}
                              >
                                {Item.concat}
                              </li>
                            </ul>
                          ))}
                        </Grid>
                      </>
                    )}
                  </Grid>
                ) : (
                  <>
                    <Grid marginTop={2}>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "525",
                          lineHeight: "16px",
                          textAlign: "left",
                          color: "#404E5C",
                          paddingBottom: "4px",
                        }}
                      >
                        Vehicle Name
                      </Typography>
                    </Grid>
                    <Grid sx={{ display: "flex", padding: "8px 0px" }}>
                      <Grid flex={10}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "425",
                            lineHeight: "20px",
                            textAlign: "left",
                            color: "Neutral/900",
                            marginLeft: "8px",
                          }}
                        >
                          {carname.concat}
                        </Typography>
                      </Grid>
                      <Grid
                        flex={2}
                        sx={{
                          cursor: "pointer",
                          flex: "5",
                          textAlign: "right",
                        }}
                      >
                        <Button onClick={() => setCarname(null)}>change</Button>
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            ) : null}

            <Grid sx={{ width: "100%", paddingTop: "20px" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: "12px",
                  fontWeight: "525",
                  lineHeight: "16px",
                  textAlign: "left",
                  color: "#404E5C",
                  paddingBottom: "4px",
                }}
              >
                Select Insurer*
              </Typography>
              {/* <FormControl sx={{ m: 1, minWidth: "100%", maxWidth: "300px" }}>
                <Select
                  // value={vehicleType}
                  onChange={(event: any) => selectInsurer(event.target.value)}
                  displayEmpty
                  sx={{ height: 40, marginLeft: "-10px !important" }}
                  required
                >
                  {insurerList.map((Item: any, index: any) => (
                    <MenuItem value={Item.Code}>{Item.Name}</MenuItem>
                  ))}
                </Select>
              </FormControl> */}
              <FormControl fullWidth>
                <Select
                  multiple
                  value={selectedInsurerNames}
                  onChange={handleChange}
                  renderValue={(selected) => selected.join(", ")}
                  onClose={() => setClose(false)}
                  onOpen={() => setClose(true)}
                  open={close}
                >
                  <Grid display={"flex"}>
                    <Box sx={{ flex: "8" }}>
                      <Button onClick={() => setClose(false)}>done</Button>
                    </Box>
                    <Box sx={{ alignItems: "right", marginRight: "10px" }}>
                      <Button onClick={resetInsurer}>RESET</Button>
                    </Box>
                  </Grid>
                  {insurerList.map((item: any) => (
                    <MenuItem key={item.Code} value={item.Code}>
                      <ListItemIcon>
                        <Checkbox
                          checked={selectedInsurerNames.indexOf(item.Code) > -1}
                        />
                      </ListItemIcon>
                      <ListItemText primary={item.Code} sx={{ width: "50%" }} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* <FormControl sx={{ m: 1, minWidth: "100%", maxWidth: "30px" }}>
                <Select
                  // value={vehicleType}
                  onChange={(event: any) => selectInsurer(event.target.value)}
                  displayEmpty
                  multiline
                  sx={{ marginLeft: "-10px !important" }}
                  required
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      padding: "12px",
                    }}
                  >
                    <option value=""> Select </option>
                  </Box>
                  {insurerList.map((Item: any, index: any) => (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                        padding: "12px",
                      }}
                    >
                      <input type="checkbox" style={{ margin: "0 8px" }} />
                      <option value={Item.code}> {Item.Name} </option>
                    </Box>
                  ))}
                </Select>
              </FormControl> */}
            </Grid>

            <Grid sx={{ width: "100%", paddingTop: "20px" }}>
              <FormControlLabel
                control={<Checkbox />}
                label="All Insurers"
                labelPlacement="end"
                onChange={allSeleted}
                checked={checked}
              />{" "}
            </Grid>

            {/* {code == "Goods_Carrying_Vehicle" ? (
              <Grid sx={{ width: "100%", paddingTop: "20px" }}>
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: "12px",
                    fontWeight: "525",
                    lineHeight: "16px",
                    textAlign: "left",
                    color: "#404E5C",
                    paddingBottom: "4px",
                  }}
                >
                  Carier Vehicle
                </Typography>
                <AppInput
                  onChange={(item: any) => console.log(item.target.value)}
                />
              </Grid>
            ) : null} */}
            {/* {vehicleType == "MV" || vehicleType == "Passenger_Carrying_Vehicle" ? (
              <Grid sx={{ width: "100%", paddingTop: "20px" }}>
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: "12px",
                    fontWeight: "525",
                    lineHeight: "16px",
                    textAlign: "left",
                    color: "#404E5C",
                    paddingBottom: "4px",
                  }}
                >
                  Vehicle Sub Type
                </Typography>
                <FormControl sx={{ m: 1, minWidth: "100%" }}>
                  <Select
                    value={subVehicle}
                    onChange={(event: any) => setSubVehicle(event.target.value)}
                    displayEmpty
                    sx={{ height: 40, marginLeft: "-10px !important" }}
                    required
                  >
                    {vehicleSubTypeList.map((Item: any, index: any) => (
                      <MenuItem value={Item.Code}>{Item.Name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ) : null} */}
            {error == true ? (
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "425",
                  lineHeight: "20px",
                  textAlign: "center",
                  color: "red",
                  marginLeft: "8px",
                }}
              >
                Sorry No relevent data found
              </Typography>
            ) : null}
            <Grid sx={{ width: "100%", paddingTop: "20px" }}>
              <Button
                variant="contained"
                type="submit"
                onClick={movePayuot}
                fullWidth
                disabled={ButtonDisable()}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : page == 1 ? (
        <Grid
          sx={{
            maxWidth: "720px",
            margin: "0 auto",
          }}
        >
          {/* HEADER */}
          {loader == true ? (
            <>Loading </>
          ) : (
            <>
              <Grid
                sx={{
                  width: "100%",
                  display: "flex",
                  background: "#F0F6FD",
                  position: "relative",
                }}
              >
                <Grid sx={{ flex: "1", padding: "14px 0 0 20px" }}>
                  <img
                    src="../images/backIcon.png"
                    style={{ width: "12px", height: "10px", cursor: "pointer" }}
                    onClick={() => setpageno(page - 1)}
                  />
                </Grid>
                <Grid
                  sx={{
                    padding: "16px 0px",
                    flex: "7",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "525",
                      lineHeight: "24px",
                      textAlign: "left",
                      color: "#232B33",
                      paddingLeft: "16px",
                    }}
                  >
                    Vehicle Coins
                  </Typography>
                </Grid>
                <Grid sx={{ flex: "1", paddingTop: "12px" }}>
                  <img
                    src={logoutImg}
                    style={{ width: "24px", height: "24px", cursor: "pointer" }}
                    onClick={() => logout()}
                  />
                  {/* <Button onClick={() => logout()}>LogOut</Button> */}
                </Grid>
              </Grid>
              <Grid
                sx={{
                  padding: "12px",
                  width: "100%",
                  backgroundColor: "#FFFFFF",
                  boxShadow:
                    "0px 0px 1px rgba(20, 24, 28, 0.06), 0px 1px 2px rgba(20, 24, 28, 0.1)",
                }}
              >
                <Grid sx={{ width: "100%", display: "flex" }}>
                  <Grid sx={{ flex: "10", width: "80%" }}>
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: "12px",
                        fontWeight: "525",
                        lineHeight: "16px",
                        textAlign: "left",
                        color: "#232B33",
                      }}
                    >
                      {/* {carname.concat.lenght == 0 ? null : carname.concat} */}
                      {vehicleType == Miscellaneous_Vehicle
                        ? false
                        : carname.concat}
                    </Typography>
                  </Grid>
                  <Grid sx={{ width: "20%", flex: "1" }}>
                    <img
                      src="../images/Vector.png"
                      style={{
                        width: "12px",
                        height: "12px",
                        cursor: "pointer",
                      }}
                      onClick={() => setpageno(page - 1)}
                    />
                  </Grid>
                </Grid>
                <Grid sx={{ width: "100%", display: "flex" }}>
                  <Grid sx={{ flex: "1", width: "100%" }}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "425",
                        lineHeight: "20px",
                        textAlign: "left",
                        color: "#63798F",
                      }}
                    >
                      Vehicle Type: {vehicleType}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "425",
                        lineHeight: "20px",
                        textAlign: "left",
                        color: "#63798F",
                      }}
                    >
                      Policy Type: {policyType}
                    </Typography>
                  </Grid>
                  <Grid sx={{ width: "100%", flex: "1" }}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "425",
                        lineHeight: "20px",
                        textAlign: "left",
                        color: "#63798F",
                      }}
                    >
                      RTO: {rtoCity}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "425",
                        lineHeight: "20px",
                        textAlign: "left",
                        color: "#63798F",
                      }}
                    >
                      Plan Type: {planType}
                    </Typography>
                  </Grid>
                </Grid>
                {/* {togel == 1 ? ( */}

                <Grid sx={{ width: "100%", padding: "4px 0px" }}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "425",
                      lineHeight: "20px",
                      textAlign: "left",
                      color: "#63798F",
                    }}
                  >
                    Insurer Selected:
                  </Typography>
                  <Grid sx={{ display: "flex", flexWrap: "wrap" }}>
                    {selectedInsurerNames.map(
                      (item: any, i: number, currArr: string[]) => {
                        return (
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "425",
                              lineHeight: "20px",
                              textAlign: "left",
                              color: "#63798F",
                            }}
                          >
                            {selectedInsurerNames.length == 1 ||
                            i == currArr.length - 1
                              ? `${item}`
                              : `${item},`}
                          </Typography>
                        );
                      }
                    )}
                  </Grid>
                </Grid>
                {/* ) : null}
            <Grid
              sx={{
                width: "100%",
                borderTop: "1px solid #DFE5EB",
                marginTop: "8px",
                marginRight: "12px",
              }}
            >
              {togel == 0 ? (
                <img
                  src="../images/Vector 10.png"
                  style={{ width: "10%", margin: "-17px 0px 0px 40% " }}
                  onClick={() => settogel(1)}
                />
              ) : (
                <img
                  src="../images/Vector 10.png"
                  style={{ width: "10%", margin: "-17px 0px 0px 40% " }}
                  onClick={() => settogel(0)}
                />
              )}
            </Grid> */}
                {/* <Grid sx={{ width: "100%", display: "flex" }}>
              <Grid sx={{ flex: "1", width: "80%" }}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "425",
                    lineHeight: "20px",
                    textAlign: "center",
                    color: "#63798F",
                  }}
                >
                  From:
                </Typography>
                <Grid sx={{ width: "100%" }}></Grid>
              </Grid>
              <Grid sx={{ flex: "1" }}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "425",
                    lineHeight: "20px",
                    textAlign: "center",
                    color: "#63798F",
                  }}
                >
                  Till:
                </Typography>
              </Grid>
            </Grid> */}
                {/* <Grid sx={{ width: "100%", display: "flex" }}>
              <AppDateSelect
                day={day}
                month={month}
                year={year}
                handleDayChange={setday}
                handleMonthChange={setmonth}
                handleYearChange={setyear}
                minAge={0}
                maxAge={100}
                menuPlacement={"bottom"}
                isSearchable={false}
              />
            </Grid> */}
              </Grid>
              {/* <Grid
            sx={{
              width: "100%",
              background: "#f0f6fdab",
              display: "flex",
              justifyContent: "center",
              overflow: "auto",
              padding: "12px 0px",
            }}
          >
            {data.map((item: any) => {
              return (
                <li>
                  <Grid
                    sx={{
                      boxShadow:
                        "0px 0px 1px rgba(20, 24, 28, 0.06), 0px 1px 2px rgba(20, 24, 28, 0.1)",
                      background: "#ffff",
                      borderRadius: "100px",
                      padding: "4px 12px",
                      marginLeft: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        lineHeight: "16px",
                        textAlign: "center",
                        color: "#404E5C",
                      }}
                    >
                      {item}
                    </Typography>
                  </Grid>
                </li>
              );
            })}
          </Grid> */}
              <Grid sx={{ padding: "0 16px 16px 16px", width: "100%" }}>
                {payoutData.length != 0 ? (
                  <>
                    {payoutData.map((item: any) => {
                      return (
                        <Grid
                          sx={{
                            marginTop: "16px",
                            width: "100%",
                            background: "#ffff",
                            boxShadow:
                              "0px 0px 2px rgba(41, 71, 190, 0.06), 0px 3px 8px rgba(41, 71, 190, 0.1)",
                            borderRadius: "4px",
                            padding: "12px 0px 20px 12px",
                          }}
                        >
                          <Grid sx={{ display: "flex" }}>
                            <Grid sx={{ flex: "9", padding: "0px 0 12px 0" }}>
                              {/* <img src="../images/insurlogo.png" style={{ width: "40%" }} /> */}
                            </Grid>
                            <Grid sx={{ flex: "1" }}>
                              <Grid display={"flex"}>
                                <Grid
                                  sx={{
                                    backgroundColor: "#D7FCF0",
                                    border: "1px solid #D7FCF0",
                                    borderRadius: "50%",
                                    height: "25px",
                                    width: "25px",
                                    paddingRight: "-12px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      padding: "2px 6px",
                                      fontWeight: "600",
                                      fontSize: "14px",
                                      lineHeight: "20px",
                                    }}
                                  >
                                    {item.policy_type == "OD"
                                      ? "O"
                                      : item.policy_type == "TP"
                                      ? "T"
                                      : item.policy_type == "NET"
                                      ? "N"
                                      : null}
                                  </Typography>
                                </Grid>
                                <Grid
                                  sx={{
                                    backgroundColor: "#FFCC00",
                                    display: "flex",
                                    padding: "4px 3px",
                                    height: "25px",
                                    marginLeft: "12px",
                                    borderRadius: "6px 0px 0px 6px",
                                  }}
                                >
                                  <img src="../images/star.png" />
                                  <Typography
                                    sx={{
                                      padding: "2px 6px",
                                      color: "#FFFFFF",
                                      fontWeight: "500",
                                      fontSize: "12px",
                                      lineHeight: "16px",
                                    }}
                                  >
                                    {item.payout_od.length == 0
                                      ? item.payout_tp
                                      : item.payout_od}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid sx={{ width: "100%" }}>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "525",
                                lineHeight: "20px",
                                textAlign: "left",
                                color: "#232B33",
                              }}
                            >
                              {item.insurer}
                            </Typography>
                          </Grid>
                          <Grid
                            sx={{
                              width: "100%",
                              padding: "12px 12px 12px 0px",
                              display: "flex",
                            }}
                          >
                            <Grid
                              sx={{
                                width: "33%",
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: "425",
                                  lineHeight: "16px",
                                  textAlign: "left",
                                  color: "#63798F",
                                }}
                              >
                                Vehicle Age
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "525",
                                  lineHeight: "20px",
                                  textAlign: "left",
                                  color: "#404E5C",
                                }}
                              >
                                {item.vehicleAge}
                              </Typography>
                              {item.payin ? (
                                <Typography
                                  sx={{ fontSize: "14px", color: "#63798F" }}
                                >
                                  Coins :-{" "}
                                  <span style={{ color: "#404E5C" }}>
                                    {item.payin}
                                  </span>
                                </Typography>
                              ) : null}
                            </Grid>
                            {vehicleType == Private_Car ? (
                              <Grid sx={{ width: "33%" }}>
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    fontWeight: "425",
                                    lineHeight: "16px",
                                    textAlign: "center",
                                    color: "#63798F",
                                  }}
                                >
                                  NCB
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "525",
                                    lineHeight: "20px",
                                    textAlign: "center",
                                    color: "#404E5C",
                                  }}
                                >
                                  {item.ncb_criteria}
                                </Typography>
                              </Grid>
                            ) : (
                              <Grid sx={{ width: "33%" }}>
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    fontWeight: "425",
                                    lineHeight: "16px",
                                    textAlign: "left",
                                    color: "#63798F",
                                  }}
                                >
                                  Vehicle Sub Type
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "525",
                                    lineHeight: "20px",
                                    textAlign: "left",
                                    color: "#404E5C",
                                  }}
                                >
                                  {item.vehicle_sub_type}
                                </Typography>
                              </Grid>
                            )}
                          </Grid>
                          {item.otherParmeters.lenght == 0 ? null : (
                            <Grid
                              sx={{
                                borderTop: "1px solid #DFE5EB",
                                padding: "12px 12px 0px 0px",
                                marginRight: "12px",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: "425",
                                  lineHeight: "20px",
                                  textAlign: "left",
                                  color: "#63798F",
                                }}
                              >
                                Other Parameters
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: "525",
                                  lineHeight: "16px",
                                  textAlign: "left",
                                  color: "#404E5C",
                                  wordBreak: "break-word",
                                }}
                              >
                                {item.otherParmeters
                                  .split(",")
                                  .map((item: any) => (
                                    <p>{item}</p>
                                  ))}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      );
                    })}
                  </>
                ) : (
                  <>Sorry, no result found for the selected category. </>
                )}
              </Grid>
            </>
          )}
          {togelage == 1 ? <VehicleAge /> : null}
        </Grid>
      ) : null}
    </>
  );
}
export default Payinpayout;
