export const insurers = <any>[
  { value: "0", label: "HDFC ERGO" },
  { value: "1", label: "ICICI Lombard" },
  { value: "2", label: "Go Digit General" },
  { value: "3", label: "Tata AIG" },
  { value: "4", label: "Kotak Mahindra General" },
  { value: "5", label: "Royal Sundaram" },
  { value: "6", label: "Bajaj Allianz" },
  { value: "7", label: "Reliance General" },
];

export const vehicleTypes = <any>[
  { value: "0", label: "Private Car" },
  { value: "1", label: "Two Wheeler" },
  { value: "2", label: "Goods Carrying Vehicle" },
  { value: "3", label: "Miscellaneous Vehicle" },
];

export const productTypes = <any>[
  { value: "1", label: "Comprehensive" },
  { value: "2", label: "Zero Dep" },
  { value: "3", label: "Third Party" },
  { value: "4", label: "Standalone OD" },
];

export const vehicleAge = <any>[
  { value: "15", label: "15 Yrs" },
  { value: "14", label: "14 Yrs" },
  { value: "13", label: "13 Yrs" },
  { value: "12", label: "12 Yrs" },
  { value: "11", label: "11 Yrs" },
  { value: "10", label: "10 Yrs" },
  { value: "9", label: "9 Yrs" },
  { value: "8", label: "8 Yrs" },
  { value: "7", label: "7 Yrs" },
  { value: "6", label: "6 Yrs" },
  { value: "5", label: "5 Yrs" },
  { value: "4", label: "4 Yrs" },
  { value: "3", label: "3 Yrs" },
  { value: "2", label: "2 Yrs" },
  { value: "1", label: "1 Yrs" },
];

