import {
  Button,
  FormControl,
  Grid,
  Input,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import AppInputLogin from "../../components/AppInputLogin";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Login() {
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [page, setpageno] = useState(0);
  const [isPos, setIspos] = useState<boolean>(false);
  const [userId, setUserId ] = useState<String>("")

  useEffect(() => {
    if (localStorage.getItem("G_T")) {
      navigate("/payinpayout");
    }
  }, []);

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!isNaN(Number(event.target.value))) {
      setMobileNumber(event.target.value);
    }
  };

  const handleCodeChange = (event: string) => {
    if (!isNaN(Number(event))) {
      setOtp(event);
      if (event.length === 4) {
        // onCodeSubmit(event);
      }
    }
  };

  const buttonDisable = () => {
    let status = true;
    if (mobileNumber.length == 10) {
      status = false;
    }
    return status;
  };

  const sendOtp = async () => {
    const getOtp = await axios({
      method: "POST",
      url: `https://payoutgrid-stg.gromo.in/payoutgrid/v1/sendOtp`,
      headers: {
        Authorization:
          "ff0bb973c857b105bf20d8d53bf4a3a3cfe473ec1d47700c339e2b88e81df053",
      },
      data: {
        phone: mobileNumber,
      },
    });
    if (getOtp.data.isSuccess) {
      setUserId(getOtp.data.data.posId);
      setpageno(page + 1);
    } else {
      setIspos(true)
    }
  };

  const verifyOtp = async () => {
    const setOtp = await axios({
      method: "POST",
      url: `https://payoutgrid-stg.gromo.in/payoutgrid/v1/verifyOtp`,
      headers: {
        Authorization:
          "ff0bb973c857b105bf20d8d53bf4a3a3cfe473ec1d47700c339e2b88e81df053",
      },
      data: {
        phone: mobileNumber,
        posId: userId,
        otp: parseInt(otp),
      },
    });
    if (setOtp.data.isSuccess) {
      localStorage.setItem("G_T",`${userId}`);
      if(setOtp.data.jwt){
        localStorage.setItem("jwtToken",setOtp.data.jwt);
      }
      navigate("/payinpayout");
    } else {
      alert(setOtp.data.msg);
    }
  };
  return (
    <>
      <Grid
        sx={{
          maxWidth: "720px",
          margin: "0 auto",
          position: "relative",
          height: "100vh",
        }}
      >
        <Grid sx={{ padding: "32px 24px 24px 24px", background: "#FAFDFF" }}>
          {page == 0 ? (
            <Grid>
              <Grid>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "24px",
                    textAlign: "left",
                    color: "#232B33",
                  }}
                >
                  Enter mobile number
                </Typography>
              </Grid>
              <Grid sx={{ paddingTop: 1 }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "425",
                    lineHeight: "20px",
                    textAlign: "left",
                    color: "#232B33",
                  }}
                >
                  Mobile number is required for the verification purpose
                </Typography>
              </Grid>
              <Grid sx={{ paddingTop: 3 }}>
                <AppInputLogin
                  placeholder="Enter mobile number"
                  type={"tel"}
                  max={10}
                  maxLength={10}
                  value={mobileNumber}
                  onChange={handlePhoneNumberChange}
                  autoFocus
                />
              </Grid>
              {isPos == true ? (
                <Grid sx={{ paddingTop: 1 }}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "425",
                      lineHeight: "20px",
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    Partner is not registered
                  </Typography>
                </Grid>
              ) : null}
              <Grid
                sx={{
                  position: "absolute",
                  bottom: "24px",
                  left: "16px",
                  right: "16px",
                }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  disabled={buttonDisable()}
                  onClick={sendOtp}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Box
              sx={{
                width: "100%",
                float: "left",
                clear: "both",
              }}
            >
              <Grid
                sx={{
                  width: "100%",
                  float: "left",
                }}
              >
                {/* <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "20px",
                    lineHeight: "22px",
                    color: "#667080",
                    padding: "12px 0px ",
                  }}
                >
                  Enter OTP
                </Typography> */}

                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: "#404E5C",
                    padding: "12px 0px ",
                  }}
                >
                  We have sent a 4-digit OTP on
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 425,
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: "#404E5C",
                    padding: "0px 0px ",
                  }}
                >
                  +91 {mobileNumber}
                </Typography>
              </Grid>
              <FormControl>
                {/* <AppInput placeholder="Enter OTP Which You Recieve On Your Phone Number " input/> */}
                <OtpInput
                  value={otp}
                  onChange={handleCodeChange}
                  numInputs={4}
                  inputStyle={{
                    fontSize: "20px",
                    width: "40px",
                    height: "52px",
                    margin: "0 8px",
                    outline: "none",
                    border: `1px solid lightgray`,
                    borderRadius: 6,
                  }}
                  containerStyle={{
                    padding: "8px",
                  }}
                  isInputNum
                  shouldAutoFocus
                />
                <Typography
                  sx={{
                    fontWeight: 425,
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: "#0075E3",
                    padding: "0px 0px ",
                  }}
                >
                  Resend OTP
                </Typography>

                <Grid
                  sx={
                    {
                      // position: "absolute",
                      // bottom: "24px",
                      // left: "16px",
                      // right: "16px",
                    }
                  }
                >
                  <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    disabled={buttonDisable()}
                    onClick={verifyOtp}
                  >
                    Submit
                  </Button>
                </Grid>
              </FormControl>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
}
export default Login;
